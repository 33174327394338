<template>
	<div
		class="bg-black full-height position-relative"
	>
		<Loading
			v-if="is_loading"
		></Loading>
		<template
			v-else
		>
			<div
				class="position-fixed w-100"
				style="top: 0; left: 0; height: 50px; z-index: 9"
			>
				<div class="justify-space-between bg-top-gra">
					<button
						@click="goBack"
					><img :src="require('@/assets/image/icon_back_white.svg')"/></button>
					<button
						class="pa-10-20"
						@click="onMore"
					><img :src="require('@/assets/image/icon_more_white.svg')"/></button>
				</div>
			</div>

			<div class="full-height bg-black color-white position-relative">
				<div
					v-show="items.length > 0"
					class="full-height overflow-hidden"
				>
					<ul
						class=""
						style="height: 300%;"
						ref="scroll-box"
					>
						<li
							v-for="(item, index) in items"
							:key="'item_' + index"
							:class="index_move == index ? 'down-scale' : 'up-scale'"
							style="height: 33.33%"
							class="position-relative"
						>
							<div
								class="full-height"
								:style="'background: url(' + item.post_file_list[0].post_appendix_file_org_url +') no-repeat 100% ; background-size: contain; filter: brightness(80%); '"
							>
							</div>
						</li>
					</ul>

					<div
						class="position-absolute-full gap-10"
					>
						<div
							class="wrap-div-shorts full-height flex-column justify-center bg-black position-relative"
							:class="item_video.bulletin_number && is_on_move ? 'fade' : 'out'"
						>
							<video
								autoplay
								loop
								playsinline

								id="video"
								ref="video"
							>
							</video>
						</div>

						<div
							class="position-fixed-full "
						>
							<div
								class="position-absolute-full flex-column justify-center items-center in-hover"
								@touchstart="touchStart"
								@touchend="touchEnd"
								style="z-index: 8"
							>
								<div
									class="flex-1 width-100"
									@click="volumeMute"
								>
									<div class="text-right pt-50">
										<div class="pa-20">
											<v-icon
												v-if="!item_video.muted"
												class="color-white sound"
											>mdi-volume-high</v-icon>
											<v-icon
												v-else
												class="color-white "
											>mdi-volume-mute</v-icon>
										</div>
									</div>
								</div>
								<div
									class="flex-1 flex-column justify-center"
									@click="pausePlay"
								>
									<v-icon
										v-if="item_video.paused"
										class="color-white" large >mdi-play-circle-outline</v-icon>
									<v-icon
										v-else
										class="color-white pause" large >mdi-pause-circle-outline</v-icon>
								</div>
								<div class="flex-1 flex-column justify-end width-100">
									<div class="justify-space-between pb-100 prl-20  flex-wrap">
										<div class="flex-column justify-end flex-4">
											<ProfileImage
												:user="user"
												:item_info="item_video"
												:cartel_info="item_cartel"
												:is_nickname="true"
												:is_cartel_name="true"
												icon_type="ss"
											></ProfileImage>
											<div
												class="mt-10 size-px-13 "
												:class="!item_video.is_white ? 'white-space' : 'white-space-on'"
												@click="$set(item_video, 'is_white', !item_video.is_white)"
											>
												{{ item_video.contents}}
											</div>
										</div>
										<div class="flex-column justify-end flex-1">
											<button
												v-if="item_video.sympaty_icon_code"
												class="color-white"
											>
												<img :src="require('@/assets/image/icon_shorts_heart.svg')"/> <br/>
												<span class="size-px-12 font-weight-600">{{  item_video.sympaty_count | makeComma }}</span>
											</button>
											<button
												v-else
												class="color-white"
												@click="postEmoji({ code: 'CA01100001'})"
											>
												<img :src="require('@/assets/image/icon_short_heart_white.svg')"/> <br/>
												<span class="size-px-12 font-weight-600">{{  item_video.sympaty_count | makeComma }}</span>
											</button>
											<button
												class="color-white mt-20"
												@click="getComment"
											>
												<img :src="require('@/assets/image/icon_short_comment.svg')"/> <br/>
												<span class="size-px-12 font-weight-600">{{  item_video.comment_count | makeComma }}</span>
											</button>
											<button
												v-if="is_write"
												class="color-white mt-20"
												@click="toItem"
											>
												<img :src="require('@/assets/image/icon_short_upload.svg')"/> <br/>
												<span class="size-px-12 font-weight-600">Upload</span>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Empty
					v-show="items.length < 1"
					class="full-height"
				>
					<template
						v-slot:body
					>
						<div
							class="text-center character3 pt-140"
						>
							<div class="size-px-20 font-weight-bold">등록된 숏플이 없습니다.</div>
							<div class="mt-15 color-bbb size-px-14">짧은 동영상을 공유하고 멤버들과 소통해보세요.</div>
							<div class="color-bbb size-px-14">숏플 등록은 가입된 카르텔에서 가능합니다.</div>
							<div class="mt-20">
								<button
									v-if="is_write"
									class="btn_l btn_fill_blue"
									@click="toItem"
								>첫 숏플 등록</button>
							</div>
						</div>
					</template>
				</Empty>
			</div>
		</template>

		<comment_list
			v-if="is_on_comment"
			:user="user"
			:cartel="item_cartel"
			:bbs="item_video"

			class="layer-popup"

			@click="item_video.comment_count++"
			@cancel="update"
		></comment_list>

		<mafia064
			v-if="is_on_more"

			:user="user"
			:item_bbs="item_video"

			@cancel="is_on_more = false"
			@deleteBbs="deleteBbs"
			@modify="toModify"
			style="z-index: 1003"
		></mafia064>
	</div>
</template>

<script>
import Empty from "@/view/Layout/Empty";
import ProfileImage from "@/components/Daum/ProfileImage";
import comment_list from "@/view/CartelComment/mafiaCommentList";
import Mafia064 from "@/view/Cartel/mafia064";
import Loading from "@/view/Layout/Loading";
export default {
	name: 'CartelShorts'
	, components: {Loading, Mafia064, comment_list, ProfileImage, Empty}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'shorts'
				, title: 'shorts'
				, type: 'cartel_sub'
				, not_header: true
				, not_footer: false
			}
			, b_id: this.$route.params.b_id
			, bbs_id: this.$route.params.bbs_id
			, item_search: {
				page_number: 1
				, pagerecnum: 10
			}
			, items: []
			, is_on_move: true
			, index_move: 0
			, item_video: {

			}
			, item_cartel: {

			}
			, item_search_comment: {
				page: 1
				, pagerecnum: 10
			}
			, is_on_comment: false
			, player: ''
			, timer: ''
			, playerEvent: ''
			, is_on_more: false
			, tStart: ''
			, tEnd: ''
			, is_load_items: false
			, is_loading: true
		}
	}
	, computed: {
		is_write: function(){
			let t = false
			if(this.$route.params.idx && this.item_cartel.cartl_entry_state_code == 'CA00300004'){
				t = true
			}
			return t
		}
		, is_join: function(){
			let t = false
			if(this.item_cartel.cartl_entry_state_code == ''){
				t = true
			}
			return t
		}
	}
	, methods: {
		getData: async function(){
			try{
				let url = this.$api_url.api_path.get_open_cartel_shorts_list
				if(this.user.member_number){
					url = this.$api_url.api_path.get_shorts_total_list
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.b_id + ''
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					if(result.data.post_list.length > 0){
						this.items = this.items.concat(result.data.post_list)
						this.max = false
						this.is_load_items = true
					}else{
						this.max = true
					}
					if(this.item_search.page_number == 1 && result.data.post_list.length > 0){

						setTimeout(() => {
							this.loadVideo()
						}, 1000)

					}
					this.$storage.setQuery(this.item_search)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_more = false
				this.is_loading = false
			}
		}

		, goBack: function(){
			this.$emit('goBack')
		}
		, toItem: function(){
			this.$bus.$emit('to', { name: 'CartelShortsItem', params: {idx: this.$route.params.idx || this.item_video.cartl_number, b_id: this.$route.params.b_id || this.item_video.board_number}})
		}
		, upScale: function(){
			if(this.items.length > this.index_move + 1){
				this.is_on_move = false
				this.$set(this.items[this.index_move], 'is_upscale', true)
				this.$set(this.items[this.index_move], 'is_downscale', false)
				setTimeout( () => {
					this.index_move++
				}, 100)
			}else{
				if(this.is_load_items){
					this.is_load_items = false
					this.item_search.page_number++
					this.getData()
				}else{
					console.log('scale max')
				}
			}
		}
		, downScale: function() {
			if (this.index_move > 0) {

				this.is_on_move = false
				this.$set(this.items[this.index_move], 'is_downscale', true)
				this.$set(this.items[this.index_move], 'is_upscale', false)
				setTimeout(() => {
					this.index_move--
					this.$set(this.items[this.index_move], 'is_upscale', true)
					this.$set(this.items[this.index_move], 'is_downscale', false)
				}, 100)

			} else {
				console.log('scale zero')
			}
		}

		, getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getComment: async function(){
			if(!this.user.member_number){
				this.$bus.$emit('onLogin')
				return false
			}
			this.is_on_comment = true
		}
		, postEmoji: async function(emoji){
			if(!this.user.member_number){
				this.$bus.$emit('onLogin')
				return false
			}
			try{
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_emoji
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx || this.item_video.cartl_number
						, board_number: this.item_video.board_number
						, bulletin_number: this.item_video.bulletin_number
						, sympaty_icon_code: emoji.code
					}
					, type: true
				})

				if(result.success){
					this.item_video.is_empathy = emoji.code
					this.item_video.sympaty_icon_code = emoji.code
					this.item_video.is_emoji = false
					this.item_video.sympaty_count++
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_emoji = false
				this.$bus.$emit('on', false)
			}
		}

		, setSteamVideo: function(item){
			// console.log('setSteamVideo', item)
			this.$set(this.item_video, 'src', item.post_appendix_file_org_url)

			if(!this.$route.params.idx) {
				this.item_cartel = {
					cartl_number: this.item_video.cartl_number
					, board_number: this.item_video.board_number
					, bulletin_number: this.item_video.bulletin_number
					, cartl_name: this.item_video.cartl_name
				}
			}

			if(this.player){
				this.player.pause()
			}

			setTimeout( () => {

				if(this.player){

					this.player.src(this.item_video.src);
					this.player.play();
					console.log('play')

					this.is_on_move = true
				}
			}, 300)
		}
		, loadVideoEvent: function(err){
			if(err.code === 404){
				setTimeout( () => {
					this.player.src(this.item_video.src);
					this.player.play();
				}, 5000)
			}
		}

		, loadVideo: function(){
			console.log('player initialize start')
			window.registerIVSTech(window.videojs)
			// IVS Player와 Video.js 통합 설정
			const videoOptions = {
				techOrder: ["AmazonIVS"],
				autoplay: true,
				playsinline: true
			};
			// console.log(document.getElementById('video'))
			this.player = window.videojs('video', videoOptions, () => {
				console.log('Player is ready to use');
			});

			// console.log(this.player)
			this.player.src(this.item_video.src);
			this.player.play();
			this.player.responsive(true);
			// console.log('tag', this.player.tags)

			this.playerEvent = this.player.getIVSEvents().PlayerEventType;
			this.player.getIVSPlayer().addEventListener(this.playerEvent.ERROR, this.loadVideoEvent, true);
			this.player.on('waiting', function () {
				console.log('Video is buffering...');
				// 버퍼링 상태에서 필요한 처리를 수행
			});

			this.player.on('canplay', function () {
				console.log('Video can start playing.');
				this.on_video = true
				// 버퍼링이 완료되고 재생이 시작 가능한 상태에서 필요한 처리를 수행
			});

			this.player.on('playing', function () {
				console.log('Video is playing.');
				this.on_video = true
				// 비디오 재생이 시작될 때 필요한 처리를 수행
			});

			this.player.on('stalled', function () {
				console.log('Video playback stalled.');
				this.on_video = false
				// 재생이 중단되거나 지연될 때 필요한 처리를 수행
			});

			this.is_streaming = true

			if(this.item_search.page_number == 1){

				if(this.bbs_id){
					this.items.filter( (item, index) => {
						if(this.bbs_id == item.bulletin_number){
							this.index_move = index
						}
					})
					this.bbs_id = ''
				}
				let video = this.items[this.index_move]
				this.item_video = video
				this.setSteamVideo(video.post_file_list[1])
			}

		}
		, loadWheel: function(e){

			if(this.is_on_comment){
				return false
			}
			if(this.is_on_move){
				let type = e.deltaY

				if(type > 0){
					this.upScale()
				}else{
					this.downScale()
				}
			}else{
				console.log('alreay move')
			}

		}

		, update: async function(){
			this.is_on_comment = false
		}
		, onMore: function(){
			if(this.user.member_number){
				if(this.item_video.bulletin_number){
					this.is_on_more = true
				}
			}else{
				this.$bus.$emit('onLogin')
			}
		}
		, deleteBbs: async function(){
			this.is_on_more = false
			this.items.splice(this.index_move, 1)
			this.upScale()
		}
		, toModify: function(){
			this.$bus.$emit('to', { name: 'CartelShortsItemDetail', params: {idx: this.item_video.cartl_number, b_id: this.item_video.board_number, bbs_id: this.item_video.bulletin_number}})
		}
		, touchStart: function(e){
			// console.log('touchStart', e.changedTouches[0].screenY)
			this.tStart = e.changedTouches[0].screenY
		}
		, touchEnd: function(e){
			// console.log('touchEnd', e.changedTouches[0].screenY)
			this.eStart = e.changedTouches[0].screenY

			if(this.is_on_move) {
				if (this.tStart - this.eStart > 50) {
					this.upScale()
				} else if(this.tStart - this.eStart < -50){
					this.downScale()
				}
			}else{
				console.log('already touch')
			}
		}
		, pausePlay: function(){
			let d = this.player
			if(d.paused()){
				d.play()
				this.$set(this.item_video, 'paused', false)
			}else{
				d.pause()
				this.$set(this.item_video, 'paused', true)
			}
		}
		, volumeMute: function(){

			let d = this.player

			d.muted(!d.muted())
			this.$set(this.item_video, 'muted', d.muted())
		}
	}
	, mounted() {
		console.log('mounted')

		if(this.$route.params.idx && this.user.member_number){
			this.getCartel()
		}
		this.getData()

	}
	, created() {
		console.log('created')
		this.$bus.$emit('onLoad', this.program)
		console.log(this.$route.params.b_id)

		document.addEventListener('wheel', this.loadWheel, true)
	}
	, destroyed() {
		document.removeEventListener('wheel', this.loadWheel ,true)

		if(this.player){
			this.player.dispose()
		}
	}
	, watch: {
		index_move: {
			immediate: true
			, handler: function(call){
				// console.log('index_move', call, this.bbs_id)

				this.items[call]?.post_file_list.filter( (item) => {
					if(item.post_appendix_file_div_code == 'CA01000002'){

						this.item_video = this.items[call]
						// console.log('watch', call, this.item_video)
						this.$set(this.item_video, 'is_white', false)
						this.setSteamVideo(item)
					}
				})
			}
		}
		, is_on_move: {
			handler: function(call) {
				if (call) {
					console.log('is_on_move', call)
				}
			}
		}
	}
}
</script>

<style>
.bg-top-gra {
	background: linear-gradient(180deg, rgba(8, 8, 13, 0.20) 0%, rgba(8, 8, 13, 0.00) 100%);
}
.bg-bottom-gra {
	background: linear-gradient(0deg, rgba(8, 8, 13, 0.2) 0%, rgba(8, 8, 13, 0.2) 60%, rgba(8, 8, 13, 0) 100%);
}
.up-scale {
	height: 0% !important;
	width: 100%;
	transition: all 0.3s ease-in;
}
.down-scale {
	height: 33.33% !important;
	width: 100%;
	transition: all 0.3s ease-in;
}
.wrap-div-shorts div{
	width: 100%; height: 100%;
}
.wrap-div-shorts video {
	width: 100%; height: 100%;
}
.wrap-div-shorts {
	opacity: 0;
}
.wrap-div-shorts.fade {
	opacity: 1;
	transition: opacity 0.3s ease-in;
}
.wrap-div-shorts.out {
	opacity: 0;
	transition: opacity 0.3s ease-in;
}

.in-hover .pause{
	opacity: 0;
	transition: opacity 1s 3s ease
}

.in-hover .sound{
}

.white-space {
	width: 230px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden
}

.white-space-on {

	width: 230px;
	white-space: wrap;
	text-overflow: ellipsis;
	word-break: break-all;
}
</style>